import React, {lazy, Suspense, useEffect, useState} from 'react';

import axios from "axios";
import Cookies from "js-cookie";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import ErrorRoute from "routes/ErrorRoute";
import {FaExclamationCircle} from "react-icons/all";
import {AppContext} from "context/AppContext";
import LoadingSpinner from "components/commons/LoadingSpinner";
import Row from "react-bootstrap/Row";
import {retry} from "components/commons/LazyInit";
import {popupError, popupWarning} from "utils/basic-utils";
import tinycolor from "tinycolor2";

const DownloadCenterRoute = lazy(() => retry(() => import("routes/DownloadCenterRoute")));
const ProjectAboutRoute = lazy(() => retry(() => import("routes/ProjectRoute")));
const PostResourceRoute = lazy(() => retry(() => import("routes/admin/PostResourceRoute")));
const PostPatronRoute = lazy(() => retry(() => import("routes/admin/PostPatronRoute")));
const LoginRoute = lazy(() => retry(() => import("routes/LoginRoute")));

const CLIENT_VERSION = "1.0.0.beta.2";
const API_ROUTE = (process.env.REACT_APP_SERVER_IP_ADDRESS || "https://download.plugily.xyz") + "/api/v1";
// Minimum Web Content Accessibility Guidelines contrast ratio for text
export const WCAG_AA_CONTRAST = 3.0;

axios.interceptors.response.use(undefined, error => {
  if (error.response === undefined) {
    popupError("API server unreachable. Please contact administrator.");
    return Promise.reject(error);
  }
  if (error.response.status === 500) {
    popupError("Internal Server Error. Please contact administrator.");
  }
  if (error.response.data.errors !== undefined) {
    error.response.data.errors.forEach(err => popupWarning(err));
  }
  return Promise.reject(error);
});

const App = () => {
  const [session, setSession] = useState(Cookies.get("PSID"));
  const [userData, setUserData] = useState({data: {}, loaded: false, error: false, loggedIn: false});
  const [theme, setTheme] = useState("#ffa500");
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    axios.defaults.baseURL = API_ROUTE;
    axios.defaults.headers.common["Authorization"] = "Bearer " + session;
  }, []);

  useEffect(() => {
    if (userData.loaded) {
      return;
    }
    if (session == null && !userData.loaded) {
      setUserData({...userData, loaded: true, loggedIn: false});
      return;
    }
    if (!userData.loaded) {
      axios.get("/users/@me").then(res => {
        console.log("Successfully connected to the service, client version " + CLIENT_VERSION);
        setUserData({...userData, data: res.data, loaded: true, loggedIn: true});
      }).catch(err => {
        if (err.response === undefined || err.response.status === 401 || err.response.status === 403 || (err.response.status >= 500 && err.response.status <= 599)) {
          setUserData({...userData, loaded: true, loggedIn: false});
          return;
        }
        setUserData({...userData, loaded: true, loggedIn: false, error: true});
      });
    }
  }, [userData]);
  const onLogin = (token) => {
    setSession(token);
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    //force rerender
    setUserData({...userData, loaded: false});
  };
  const onLogOut = () => {
    delete axios.defaults.headers.common["Authorization"];
    Cookies.remove("PSID");
    setSession(null);
    history.push({pathname: location.pathname, state: null});
    setUserData({...userData, data: [], loaded: true, loggedIn: false});
  };
  const getTheme = () => {
    let color = tinycolor(theme);
    return color.clone();
  };

  if (!userData.loaded) {
    return <Row className="justify-content-center vertical-center"><LoadingSpinner/></Row>
  }
  return <AppContext.Provider value={{
    user: {
      data: userData.data,
      loggedIn: userData.loggedIn,
      session: session,
      onLogOut: onLogOut
    },
    getTheme, setTheme: color => setTheme(color)
  }}>
      <div className={"text-center py-1 bg-dark"} style={{borderBottom: "1px dashed #ffa5004D"}}>Looking for non-patron downloads? Join our Discord at <a href={"https://discord.plugily.xyz"} target={"_blank"}>https://discord.plugily.xyz</a> and access (#downloads)</div>
      <Suspense fallback={<Row className="justify-content-center vertical-center"><LoadingSpinner/></Row>}>
      <Switch>
        <Route exact path="/" component={DownloadCenterRoute}/>
        <Route exact path="/p/:id" component={ProjectAboutRoute}/>
        <Route exact path="/admin/post" component={PostResourceRoute}/>
        <Route exact path="/admin/useradd" component={PostPatronRoute}/>
        <Route path="/auth" render={() => <LoginRoute onLogin={onLogin}/>}/>
        <Route render={() => <ErrorRoute Icon={FaExclamationCircle} message="Content Not Found"/>}/>
      </Switch>
    </Suspense>
  </AppContext.Provider>
};

export default App;
