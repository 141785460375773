import React from "react";

const AppContext = React.createContext({
  user: {
    data: [], loggedIn: false, session: "",
    onLogOut: () => {
    },
  },
  getTheme: () => {},
  setTheme: theme => {},
});

export {AppContext};