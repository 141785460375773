import {Slide, toast} from "react-toastify";
import React from "react";
import {FaCheck, FaExclamation, FaExclamationTriangle} from "react-icons/fa";
import Spinner from "react-bootstrap/Spinner";
import tinycolor from "tinycolor2";
import Snackbar from "./snackbar.js";

export const convertTextToSlug = (text) => {
  let slug = text.toLowerCase();
  /* replace non alphanumeric characters with dash, replace repeated dashes with one dash
  and replace dash if slug starts or ends with it */
  slug = slug.replace(/[\W_]/g, '-')
    .replace(/(\W)\1+/g, '-')
    .replace(/^(-)/, "")
    .replace(/(-)$/, "");
  return slug;
};

export const hideNotifications = () => {
  popup("", tinycolor("#d35400"), {duration: 1});
};

export const popupNotification = (content, color) => {
  popup(content, color, {showAction: false});
};

export const popupWarning = (content = "Something unexpected happened") => {
  popup(content, tinycolor("#d35400"), {showAction: false, duration: 6000});
};

export const popupError = (content = "Something unexpected happened") => {
  popup(content, tinycolor("#e43e3e"), {showAction: false, duration: 8000});
};

const popup = (content, theme, data) => {
  Snackbar.show({
    text: content,
    textColor: theme.toString(),
    backgroundColor: theme.setAlpha(.1).toRgbString(),
    pos: "bottom-center",
    ...data
  });
};

export const getSizedAvatarByUrl = (url, sizing) => {
  return url + "?size=" + sizing;
};