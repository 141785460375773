import styled from "@emotion/styled";
import {AppContext} from "context/AppContext";
import React, {useContext} from 'react';
import {Nav} from "react-bootstrap";
import {FaAngleDown, FaSignInAlt, FaUserAlt, FaUserCog} from "react-icons/all";
import {Link} from "react-router-dom";
import {UiDropdown, UiDropdownElement} from "ui/dropdown";
import {UiContainer} from "ui/grid";
import {UiNavbar, UiNavbarBrand} from "ui/navbar";
import {getSizedAvatarByUrl} from "../../utils/basic-utils";

const OptionsButton = styled.div`
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  color: white;
  line-height: 1.65rem;
  
  &:hover, &:focus {
    color: white;
    background-color: transparent;
    box-shadow: none;
  }
`;

const TopDropdownElement = styled(UiDropdownElement)`
  padding-top: .5rem;
  border-top: 2px solid ${props => props.theme};
`;

const PageNavbar = () => {
    const context = useContext(AppContext);
    const loginUrl = "https://discordapp.com/api/oauth2/authorize?client_id=573850791299907587&redirect_uri=https%3A%2F%2Fdownload.plugily.xyz%2Fauth&response_type=code&scope=identify%20email&state=";
    const resolveTier = () => {
        if (context.user.data.admin) {
            return "Admin";
        }
        switch (context.user.data.patronType) {
            case "CLASSIC_PATRON":
                return "Minigame Insider (3$)";
            case "EXTENDED_PATRON":
                return "Source Insider (7$)";
            case "RETIRED_PATRON":
                return "Retired Patron";
            case "NOBODY":
            default:
                return "Not a Patron";
        }
    };
    const renderAdminNodes = () => {
        if (!context.user.data.admin) {
            return <React.Fragment/>
        }
        return <React.Fragment>
            <UiDropdownElement as={Link} to="/admin/post">
                <FaUserCog className="mr-1"/> Add new Update
            </UiDropdownElement>
            <UiDropdownElement as={Link} to="/admin/useradd">
                <FaUserCog className="mr-1"/> Add new Patron
            </UiDropdownElement>
        </React.Fragment>
    };
    console.log(context.getTheme());
    return <UiNavbar expand="lg">
        <UiContainer className="d-flex">
            <UiNavbarBrand theme={context.getTheme().toString()} to="/">
                <img className="img-responsive mr-2" src={"https://static.plajer.xyz/img/plugily.xyz"} height="30px" width="30px" alt=""/>
                <span className={"align-middle"}>Plugily's Patrons Page</span>
            </UiNavbarBrand>
            {context.user.loggedIn ?
                <UiDropdown menuClassName={"pt-0 rounded-top-0"} label={"Options"} toggleClassName={"px-0"} toggle={
                    <OptionsButton>
                        <img className="img-responsive rounded-circle mr-1"
                             src={getSizedAvatarByUrl(context.user.data.avatar, 64)}
                             onError={(e) => e.target.src = "https://static.plajer.xyz/img/plugily.xyz"}
                             alt="avatar" height="24" width="24" style={{border: "1px solid " + context.getTheme()}}/>
                        <FaAngleDown color={context.getTheme()}/>
                    </OptionsButton>
                }>
                    <TopDropdownElement theme={context.getTheme().setAlpha(.75).toString()}>
                        <FaUserAlt className={"mr-2"}/>
                        <strong className={"d-inline-block align-middle text-truncate"} style={{maxWidth: 100}}>{context.user.data.username}</strong>
                    </TopDropdownElement>
                    <div className="my-1"/>
                    <UiDropdownElement>
                        Tier: {resolveTier()}
                    </UiDropdownElement>
                    {renderAdminNodes()}
                    <UiDropdownElement onClick={context.user.onLogOut}>
                        Log Out
                    </UiDropdownElement>
                </UiDropdown>
                :
                <Nav.Item>
                    <a className="button btn active text-white mx-0" href={loginUrl + "%2F" /* main page redirect */}><FaSignInAlt/></a>
                </Nav.Item>
            }
        </UiContainer>
    </UiNavbar>
};

export default PageNavbar;