import React from 'react';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import {UiButton} from "ui/button";
import PageNavbar from "../components/navbar/PageNavbar";

const ErrorIcon = styled.div`
  font-size: 110px;
  color: hsl(355, 100%, 60%);
`;

const ErrorRoute = ({Icon, message, notes = ""}) => {
  return <React.Fragment>
    <PageNavbar/>
    <Container>
    <Row className="vertically-center-error text-center">
      <Col className="text-md-left justify-content-center text-center d-sm-flex d-block">
        <ErrorIcon className={"mr-sm-5 mr-0"}><Icon/></ErrorIcon>
        <div>
          <h1 className="display-3">Oh Noes!</h1>
          <h3 className="h2-responsive">{message}</h3>
          {notes && <div className={"my-1"}>{notes}</div>}
          <Link to="/">
            <UiButton label={"Go Back"} className={"mt-1 px-4 py-3"}>
              Back to the Main Page
            </UiButton>
          </Link>
        </div>
      </Col>
    </Row>
  </Container>
  </React.Fragment>
};

ErrorRoute.propTypes = {
  iconMd: PropTypes.object,
  iconSm: PropTypes.object,
  message: PropTypes.string
};

export default ErrorRoute;